<!--  -->
<template>
    <div class='page-login'>
        <div class="login-body">
            <div class="school-select">
                <van-radio-group v-model="schoolId" direction="horizontal" @change="idChange">
                    <van-radio v-for="v in schoolList" :key="v.id" :name="v.id">{{v.name}}</van-radio>
                </van-radio-group>
            </div>
            <van-form @submit="submit">
                <van-field
                    v-model="username"
                    name="用户名"
                    label="用户名"
                    placeholder="用户名"
                />
                <van-field
                    v-model="password"
                    type="password"
                    name="密码"
                    label="密码"
                    placeholder="密码"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import api from '@/API/page/login'

    export default {
        name: '',
        props: {},
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                api: new api(),
                username: null,
                password: null,
                schoolList: this.PARAMS.schoolList,
                schoolId: this.$route.query.id,
                fullPath: null
            };
        },
        methods: {
            async submit(){
                let {username, password} = this
                username = `${this.PARAMS.default_area_code}-${username}`
                let res = await this.api.login({username, password})
                if(!res) return
                this.loginSuccess()

            },
            loginSuccess(){
                let access_token = this.appData.getItem('access_token')
                let refresh_token = this.appData.getItem('refresh_token')
                // this._routeHand.goto({name: 'token-login', params: {access_token, refresh_token}, prefix})

                let {prefix} = this.schoolSelected
                let path = `${window.location.origin}`
                if(path[path.length - 1] != '/' && prefix) path = `${path}/`
                path = `${path}${prefix}/token/${access_token}/${refresh_token}`
                window.location.href = path                

                // this._routeHand.goto({name: 'Home', type: 'replace'})
            },
            async idInit(schoolId){
                if(this.schoolSelected.id && !schoolId) return
                // let {id} = this.schoolList[0] || {}
                let id = this.defaultId
                if(schoolId) id = schoolId
                if(!id) return
                this._routeHand.goto({name: 'login', query: {id}})
                await this._common.nextTick.call(this)
            },
            idChange(val){
                this.idInit(val)
            },
            async clientCheck(){
                if(!this._common.clientAppCheck('gaotu') && !this.appData.getItem('quit_callback')) return 
                let message = this.TEXT.common['token is invalid login']
                await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '退出', type: 'confirm'}
                    ]
                })
                this._common.emitEventBus('quit_app', {login: true})

            }
        },
        created() {

        },
        components: {
        },
        computed: {
            defaultId(){
                let {fullPath: path, schoolList: list} = this
                let data = null
                for(let i in list){
                    let {prefix} = list[i]
                    if(!prefix) continue
                    if(path.indexOf(prefix) > -1){
                        data = list[i]
                        break
                    }
                }
                if(!data) data = list[0] || {}
                return data.id
            },
            schoolSelected(){
                let {schoolList: list, schoolId: value} = this
                let key = 'id'
                let {data} = this._common.selected({list, value, key})
                return data || {}
            }
        },
        watch: {
            $route: {
                handler(){
                    this.schoolId = this.$route.query.id
                    this.fullPath = window.location.href
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            this.idInit()
            this.clientCheck()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-login{
        padding-top: 20px;
        padding-right: @app-padding-side;
        .school-select{
            margin-bottom: 10px;
            .van-radio__icon{
                .van-icon{
                    // width: 15px;
                    // height: 15px;
                }
            }
        }
    }
</style>