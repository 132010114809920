import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class login extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'login'
        let name = 'login'
        opt = {localKey, name}
        super(opt)
    }

    async login(opt){
        opt = opt || {}
        let {username, password, grant_type, sms_code} = opt
        if(!username) return false
        password = password || ''
        grant_type = grant_type || 'password'
        let client_id = this.PARAMS.client_id
        sms_code = sms_code || ''
        let scope = this.PARAMS.scope
        let options = {
            method: 'post',
            headers: {
                Authorization: this._userHand.getDefaultToken()
            },
            data: {
                username, password, grant_type, client_id, sms_code, scope
            },
        }
        let res = await this.requestAction('login', options)
        if(res){
            this._userHand.userLoginSet(res)
        }
        return res
    }
}

export default singleProxy(login)